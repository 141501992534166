import { useEffect, useRef, useState } from "react";

import { EditExploreContent } from "./explore-content-edit";
import ExploreTable from "./explore-content-table";
import ExploreSearch from "./explore-search";

const defaultState = {
  selectedContent: null,
  editContentModal: false,
};

const ExploreContent = () => {
  const [{ selectedContent, showEditContentModal }, setState] =
    useState(defaultState);

  const exploreRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (
      exploreRef &&
      exploreRef.current &&
      searchRef &&
      searchRef.current &&
      !selectedContent
    ) {
      exploreRef.current.refreshData();
      searchRef.current.refreshData();
    }
  }, [selectedContent]);

  return (
    <div>
      <h1>Explore Hero Slider</h1>
      <ExploreTable setContentState={setState} ref={exploreRef} />
      <h1>Search For Content</h1>
      <ExploreSearch setContentState={setState} ref={searchRef} />
      {selectedContent && showEditContentModal && (
        <EditExploreContent
          content={selectedContent}
          clearSelectedContent={() => setState(defaultState)}
        />
      )}
    </div>
  );
};

export default ExploreContent;
