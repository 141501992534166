import { Box, Paper } from '@mui/material';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';

const AppContentWrapper = ({ children, setIsAuthenticated, widgets }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
        overscrollBehavior: 'none',
      }}
    >
      <Sidebar setIsAuthenticated={setIsAuthenticated} widgets={widgets} />
      <Paper sx={{ flexGrow: 1, padding: '3rem', borderRadius: 0 }} elevation={12}>
        {children}
      </Paper>
    </Box>
  );
};
AppContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string,
      Component: PropTypes.func,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          routePath: PropTypes.string.isRequired,
          Component: PropTypes.func.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default AppContentWrapper;
