import { Avatar, Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const Profile = ({ firstName = '', lastName = '', profileImage = '' }) => {
  const initials = `${firstName?.at(0)}${lastName?.at(0)}`;
  const name = `${firstName || ''} ${lastName || ''}`.trim();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
        alignItems: 'center',
      }}
    >
      <Avatar
        alt={name}
        src={profileImage}
        sx={{ width: 30, height: 30, marginRight: '5px' }}
      >
        {initials}
      </Avatar>
      {!isEmpty(name) && <Typography sx={{ color: 'text.primary' }}>{name}</Typography>}
    </Box>
  );
};

Profile.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileImage: PropTypes.string,
};

export default Profile;
