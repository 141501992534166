/* eslint-disable no-magic-numbers */
import { useEffect, useState, useCallback } from 'react';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { capitalize } from 'lodash';

import restService from '../../services/restService';
import DataTable from '../DataTable';

const defaultSearch = {
  email: '',
  firstName: '',
  lastName: '',
  rating: 100,
  type: 'all',
};

const UserFeedbackTable = () => {
  const [feedback, setFeedback] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState(defaultSearch);

  const handleSearch = () => fetchData(search);
  const handleClear = () => {
    setSearch(defaultSearch);
    setPageSize(5);
    setCurrentPage(0);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchData = useCallback(
    (searchQuery) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([_, v]) => v !== '')
      );
      restService
        .callEndpoint({
          endpoint: 'getUserFeedback',
          body: { pageSize, page: currentPage, query: validSearchValues },
          method: 'post',
        })
        .then((response) => {
          setFeedback(response.userFeedback);
          setTotalRowCount(response.totalCount);
          setPaginationChanged(false);
        });
    },
    [currentPage, pageSize]
  );

  const columns = [
    {
      field: 'username',
      headerName: 'User',
      flex: 1,
      valueGetter: (params) => params.row?.user?.profile?.fullName,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      valueGetter: (params) => params.row?.user?.profile?.email,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => capitalize(params.row?.type),
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      valueGetter: (params) => params.row?.comment,
    },
    {
      field: 'ratings',
      headerName: 'Ratings',
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row?.ratings?.map((rating, i) => (
            <div key={i}>
              {rating.category} : {rating.rating < 0 ? 'N/A' : rating.rating}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) => params.row?.createdAt,
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  useEffect(() => {
    if (paginationChanged) {
      fetchData(search);
    }
  }, [fetchData, paginationChanged, search]);

  useEffect(() => {
    fetchData(search);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h1>User Feedback</h1>
      <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <TextField
          label="Email"
          variant="outlined"
          value={search?.email}
          onChange={(event) => setSearch({ ...search, email: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="First Name"
          variant="outlined"
          value={search?.firstName}
          onChange={(event) => setSearch({ ...search, firstName: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={search?.lastName}
          onChange={(event) => setSearch({ ...search, lastName: event.target.value })}
          onKeyDown={handleKeyDown}
        />
        <FormControl sx={{ width: '150px' }}>
          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type"
            value={search.type}
            label="Type"
            onChange={(event) => setSearch({ ...search, type: event.target.value })}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="guide">Guide</MenuItem>
            <MenuItem value="streak">Streak</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '150px' }}>
          <InputLabel id="type">Rating Up To</InputLabel>
          <Select
            labelId="rating"
            value={search.rating}
            label="Type"
            onChange={(event) => setSearch({ ...search, rating: event.target.value })}
          >
            <MenuItem value={100}>Any</MenuItem>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
        <Button variant="outline" onClick={handleClear}>
          Clear
        </Button>
      </Box>
      <DataTable
        columns={columns}
        rows={feedback}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onPaginationChange={() => setPaginationChanged(true)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRowCount={totalRowCount}
      />
    </div>
  );
};

export default UserFeedbackTable;
