/* eslint react/prop-types: 0 */
import { useState } from 'react';

import { isEmpty } from 'lodash';

import ScriptureModalForm, { DEFAULT_PROMPT } from './ScriptureModalForm';

import restService from '../../services/restService';

// eslint-disable-next-line react/prop-types
export default function AddDailyScriptureModal({ handleClose, lastDate }) {
  const [values, setValues] = useState({ prompt: DEFAULT_PROMPT });
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [existingScripture, setExistingScripture] = useState({});
  const [error, setError] = useState();

  const handleSubmit = async (overwrite = false) => {
    if (isEmpty(values.book) || isEmpty(values.chapter)) {
      setError('Book and Chapter are required.');
      return;
    } else {
      setError('');
    }

    const scripture = await restService
      .callEndpoint({
        endpoint: 'getDailyScriptureByDate',
        body: { activeAt: values.activeAt },
        method: 'post',
      })
      .catch((error) => setError(error.message));

    if (scripture && !overwrite) {
      setExistingScripture(scripture);
      setSaveModalOpen(true);
      return;
    }

    const result = await restService
      .callEndpoint({
        endpoint: 'addDailyScripture',
        body: {
          ...values,
          ...(!isEmpty(values.content?.level?._id) && {
            content: { level: { _id: values.content.level._id } },
          }),
          ...(overwrite && existingScripture && { _id: existingScripture._id }),
        },
        method: 'post',
      })
      .then((result) => {
        if (result) {
          setError('');
        }
      })
      .catch((error) => {
        return { error: error?.response?.data?.message ?? error.message };
      });

    if (result?.error) {
      setError(result.error);
      return;
    }

    setExistingScripture({});

    return handleClose();
  };

  return (
    <div>
      <ScriptureModalForm
        error={error}
        existingScripture={existingScripture}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        lastDate={lastDate}
        saveModalOpen={saveModalOpen}
        setError={setError}
        setExistingScripture={setExistingScripture}
        setSaveModalOpen={setSaveModalOpen}
        setValues={setValues}
        title="Add Daily Scripture"
        values={values}
      />
    </div>
  );
}
