import { useEffect } from 'react';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import OverwriteDialog from './OverwriteDialog';
import scriptureBooks from './scriptureBooks';
import ScriptureIntro from './ScriptureIntro';
import SelectContent from './SelectContent';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
  overflowY: 'scroll',
};

export const DEFAULT_PROMPT = 'What stood out to you?';

export default function ScriptureModalForm({
  error,
  existingScripture,
  handleClose,
  handleSubmit,
  lastDate,
  overwriteCancelText,
  overwriteConfirmText,
  overwriteText,
  saveModalOpen,
  setError,
  setExistingScripture,
  setSaveModalOpen,
  setValues,
  title,
  values,
}) {
  useEffect(() => {
    if (!isEmpty(lastDate) && isEmpty(values?.activeAt)) {
      setValues({
        ...values,
        activeAt: lastDate ? moment(lastDate).add(1, 'day').toDate() : new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDate, setValues]);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Button
          variant="contained"
          onClick={handleClose}
          style={{
            float: 'right',
            fontSize: 15,
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          X
        </Button>
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h2"
          sx={{ marginBottom: '20px', color: 'text.primary' }}
        >
          {title}
        </Typography>
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            margin: '0px 0 15px 15px',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h5" sx={{ color: 'text.primary' }}>
            Scripture
          </Typography>
          <DatePicker
            label={'Active On Date'}
            value={values.activeAt}
            onChange={(value) => setValues({ ...values, activeAt: value })}
            sx={{
              minWidth: '150',
              borderRadius: '5px',
            }}
            required
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <Autocomplete
              value={values.book || null}
              options={scriptureBooks}
              renderInput={(params) => <TextField {...params} label="Book" required />}
              onChange={(event, value) => {
                setValues({ ...values, book: value });
                setError('');
              }}
              required
              sx={{ minWidth: '175px' }}
            />
            <TextField
              value={values.chapter}
              label="Chapter"
              placeholder="Chapter"
              onChange={(event) => {
                setValues({ ...values, chapter: event.target.value });
                setError('');
              }}
              type="number"
              moreSx={{ minWidth: '70px' }}
              inputProps={{ min: 1 }}
              required
            />
            <TextField
              value={values.startVerse}
              label="Start Verse"
              placeholder="Start Verse"
              onChange={(event) =>
                setValues({ ...values, startVerse: event.target.value })
              }
              type="number"
              moreSx={{ minWidth: '70px' }}
              inputProps={{ min: 1 }}
            />
            <TextField
              value={values.endVerse}
              label="End Verse"
              placeholder="End Verse"
              type="number"
              moreSx={{ minWidth: '70px' }}
              onChange={(event) => setValues({ ...values, endVerse: event.target.value })}
              inputProps={{ min: 1 }}
            />
          </Box>
          <TextField
            placeholder={'Enter your prompt'}
            value={values.prompt}
            label="Prompt"
            onChange={(event) => setValues({ ...values, prompt: event.target.value })}
          />
        </Box>
        <Box
          sx={{
            margin: '20px 0 15px 15px',
          }}
        >
          <ScriptureIntro
            introduction={values.introduction}
            handleChange={(update) => setValues({ ...values, introduction: update })}
          />
        </Box>
        <Box
          sx={{
            margin: '0px 0 15px 15px',
          }}
        >
          <SelectContent
            setContent={(content) => setValues({ ...values, content })}
            content={values.content}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={() => handleSubmit(false)}
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Save
          </Button>
        </Box>
        <OverwriteDialog
          visible={saveModalOpen}
          handleClose={() => {
            setExistingScripture(false);
            setSaveModalOpen(false);
          }}
          handleSubmit={() => handleSubmit(true)}
          existingScripture={existingScripture}
          cancelText={overwriteCancelText}
          confirmText={overwriteConfirmText}
          overwriteText={overwriteText}
        />
      </Box>
    </Modal>
  );
}

ScriptureModalForm.propTypes = {
  error: PropTypes.string,
  existingScripture: PropTypes.shape({
    activeAt: PropTypes.string,
    book: PropTypes.string,
    chapter: PropTypes.string,
    startVerse: PropTypes.number,
    endVerse: PropTypes.number,
  }),
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  lastDate: PropTypes.string,
  overwriteCancelText: PropTypes.string,
  overwriteConfirmText: PropTypes.string,
  overwriteText: PropTypes.string,
  saveModalOpen: PropTypes.func,
  setError: PropTypes.func,
  setExistingScripture: PropTypes.func,
  setSaveModalOpen: PropTypes.func,
  setValues: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.shape({
    activeAt: PropTypes.string,
    book: PropTypes.string,
    chapter: PropTypes.string,
    startVerse: PropTypes.number,
    endVerse: PropTypes.number,
    prompt: PropTypes.string,
    introduction: PropTypes.shape({
      text: PropTypes.string,
      videoUrl: PropTypes.string,
    }),
    content: PropTypes.shape({
      level: {
        _id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
      },
      guide: {
        fields: {
          title: PropTypes.string,
        },
      },
    }),
  }),
};
