import { useState, useRef, useEffect } from "react";

import EditContent from "../components/featured-content/EditContent";
import SearchForContent from "../components/featured-content/SearchForContent";

const defaultState = {
  selectedContent: null,
  editContentModal: false,
};

const ContentSearch = () => {
  const [{ selectedContent, showEditContentModal }, setState] = useState({
    selectedContent: null,
    showEditContentModal: false,
  });
  const featuredRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (
      featuredRef &&
      featuredRef.current &&
      searchRef &&
      searchRef.current &&
      !selectedContent
    ) {
      featuredRef.current.refreshData();
      searchRef.current.refreshData();
    }
  }, [selectedContent]);

  return (
    <div>
      <h1>Featured Content</h1>
      <SearchForContent
        setContentState={setState}
        featuredContentOnly
        ref={featuredRef}
      />
      <h1>Search for Content</h1>
      <SearchForContent setContentState={setState} ref={searchRef} />
      {selectedContent && showEditContentModal && (
        <EditContent
          content={selectedContent}
          clearSelectedContent={() => setState(defaultState)}
        />
      )}
    </div>
  );
};

export default ContentSearch;
