import { Alert } from '@mui/material/index';
import { DataGrid } from '@mui/x-data-grid';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';

const DataTable = ({
  // eslint-disable-next-line react/prop-types
  columns = [],
  // eslint-disable-next-line react/prop-types
  rows = [],
  currentPage = 0,
  setCurrentPage,
  hideFooter = false,
  onPaginationChange = noop,
  pageSize,
  setPageSize,
  // eslint-disable-next-line no-magic-numbers
  pageSizeOptions = [5, 10, 20, 30],
  totalRowCount,
}) => {
  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  if (isEmpty(rows)) {
    return (
      <Alert variant="outlined" severity="info">
        No available data to display.
      </Alert>
    );
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSizeOptions={pageSizeOptions}
        hideFooter={hideFooter}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage,
          pageSize,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        getRowHeight={() => 'auto'}
      />
    </div>
  );
};

DataTable.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  hideFooter: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  onPaginationChange: PropTypes.func.isRequired,
};

export default DataTable;
