import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material/index';
import moment from 'moment';
import PropTypes from 'prop-types';

import formatPassage from './formatPassage';

export default function OverwriteDialog({
  visible,
  handleClose,
  handleSubmit,
  existingScripture = {},
  cancelText = 'Go Back',
  confirmText = 'Update Scripture',
  overwriteText = 'Do you want to overwrite it?',
}) {
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Update Daily Scripture'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`A daily scripture already exists for ${moment(
            existingScripture.activeAt
          ).format('MMMM Do, YYYY')}. ${overwriteText}`}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {`Existing Scripture: ${formatPassage(existingScripture)}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelText}</Button>
        <Button onClick={handleSubmit} autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OverwriteDialog.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  overwriteText: PropTypes.string,
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  existingScripture: PropTypes.shape({
    activeAt: PropTypes.string,
    book: PropTypes.string,
    chapter: PropTypes.string,
    startVerse: PropTypes.number,
    endVerse: PropTypes.number,
  }),
};
