import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Routes, Route, Outlet } from 'react-router-dom';

const Navigation = ({ Layout, DefaultLanding, NoMatch, widgets, setIsAuthenticated }) => {
  const getRoute = (widget) => {
    const { routePath, Component } = widget;
    return <Route key={routePath} path={routePath} element={<Component />} />;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<Layout setIsAuthenticated={setIsAuthenticated} widgets={widgets} />}
      >
        <Route path="widgets/" element={<Outlet />}>
          <Route index path="landing" element={<DefaultLanding />} />
          {widgets.map((widget) =>
            isEmpty(widget.children) ? getRoute(widget) : widget.children.map(getRoute)
          )}
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
Navigation.propTypes = {
  Layout: PropTypes.elementType.isRequired,
  DefaultLanding: PropTypes.elementType.isRequired,
  NoMatch: PropTypes.elementType.isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string,
      Component: PropTypes.func,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          routePath: PropTypes.string.isRequired,
          Component: PropTypes.func.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Navigation;
