import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import AppContentWrapper from './components/AppContentWrapper';
import AppHeader from './components/AppHeader';
import Login from './components/Login';
import Navigation from './components/Navigation';
import NoMatch from './components/NoMatch';
import restService from './services/restService';
import Commitments from './widgets/Commitments';
import CrossroadsPrayers from './widgets/CrossroadsPrayers';
import DailyScripture from './widgets/DailyScripture';
import DailyScriptureIntroductions from './widgets/DailyScriptureIntroductions';
import ExploreHero from './widgets/ExploreHero';
import FeaturedContent from './widgets/FeaturedContent';
import HomeTabSpotlight from './widgets/HomeTabSpotlight';
import Landing from './widgets/Landing';
import LivestreamSchedule from './widgets/LivestreamSchedule';
import UserFeedback from './widgets/UserFeedback';
import UserSearch from './widgets/UserSearch';

const widgets = [
  {
    routePath: 'landing',
    Component: () => <Landing />,
    label: 'Default Landing Page',
  },
  {
    routePath: 'user-search',
    Component: () => <UserSearch />,
    label: 'User Search',
  },
  {
    routePath: 'featured-content',
    Component: () => <FeaturedContent />,
    label: 'Featured Content',
  },
  {
    routePath: 'explore-hero',
    Component: () => <ExploreHero />,
    label: 'Explore Hero Slider',
  },
  {
    routePath: 'crossroads-prayers',
    Component: () => <CrossroadsPrayers />,
    label: 'Crossroads Prayers',
  },
  {
    routePath: 'home-tab-spotlight',
    Component: () => <HomeTabSpotlight />,
    label: 'Home Tab Spotlight',
  },
  {
    label: 'Daily Scripture',
    children: [
      {
        routePath: 'daily-scripture',
        Component: () => <DailyScripture />,
        label: 'Readings',
      },
      {
        routePath: 'daily-scripture-introductions',
        Component: () => <DailyScriptureIntroductions />,
        label: 'Introductions',
      },
    ],
  },
  {
    routePath: 'commitments',
    Component: () => <Commitments />,
    label: 'Commitments',
  },
  {
    routePath: 'livestream-schedule',
    Component: () => <LivestreamSchedule />,
    label: 'Livestream Schedule',
  },
  {
    routePath: 'user-feedback',
    Component: () => <UserFeedback />,
    label: 'User Feedback',
  },
];

const Layout = ({ setIsAuthenticated, widgets }) => {
  return (
    <AppContentWrapper setIsAuthenticated={setIsAuthenticated} widgets={widgets}>
      <Outlet />
    </AppContentWrapper>
  );
};
Layout.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string,
      Component: PropTypes.func,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          routePath: PropTypes.string.isRequired,
          Component: PropTypes.func.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    restService.isAuthTokenValid(setIsAuthenticated);
  }, [isAuthenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
        }}
      >
        <AppHeader />
        {isAuthenticated ? (
          <Navigation
            LoginPage={Login}
            Layout={Layout}
            DefaultLanding={Landing}
            NoMatch={NoMatch}
            widgets={widgets}
            setIsAuthenticated={setIsAuthenticated}
          />
        ) : (
          <Login setIsAuthenticated={setIsAuthenticated} />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default App;
