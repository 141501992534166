import { useState } from 'react';

import { isEmpty, noop, omit } from 'lodash';
import PropTypes from 'prop-types';

import ScriptureIntroductionForm, {
  DEFAULT_DISPLAY_TITLE,
} from './ScriptureIntroductionForm';

import restService from '../../services/restService';

const AddScriptureIntroductionModal = ({
  handleClose,
  isOpen = false,
  onSubmit = noop,
}) => {
  const [values, setValues] = useState({ displayTitle: DEFAULT_DISPLAY_TITLE });
  const [error, setError] = useState();

  const handleSubmit = async () => {
    if (isEmpty(values?.text)) {
      setError('Text is required.');
      return;
    }

    await restService
      .callEndpoint({
        endpoint: 'addDailyScriptureIntroduction',
        body: {
          ...omit(values, 'user'),
          userId: values?.user?._id,
        },
        method: 'post',
      })
      .then((result) => {
        if (result) {
          onSubmit(result?.at(0));
          setError('');
          setValues();
          handleClose();
        }
      })
      .catch((error) => setError(error?.response?.data?.message ?? error.message));
  };

  return (
    <div>
      <ScriptureIntroductionForm
        error={error}
        handleClose={() => {
          handleClose();
          setValues();
        }}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        setError={setError}
        setValues={setValues}
        title="Add Daily Scripture Introduction"
        values={values}
      />
    </div>
  );
};

AddScriptureIntroductionModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default AddScriptureIntroductionModal;
