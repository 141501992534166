import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import restService from '../../services/restService';
import DataTable from '../DataTable';
import Profile from '../Profile';

const defaultSearch = {
  email: '',
  firstName: '',
  lastName: '',
};

const UserSearchModal = ({ isOpen, handleModalClose, handleSelect }) => {
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(defaultSearch);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        const user = params?.row?.profile;

        return !isEmpty(user) ? (
          <Profile
            firstName={user?.firstName}
            lastName={user?.lastName}
            profileImage={user?.image}
          />
        ) : null;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      valueGetter: (params) => params?.row?.profile?.email,
    },
    {
      field: 'actions',
      flex: 1,
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      renderCell: (params) => {
        const user = params?.row?.profile;
        return (
          <Button
            onClick={() => {
              setSearch(defaultSearch);
              setUsers();
              handleSelect({
                _id: params.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                image: user?.image,
              });
            }}
            variant="contained"
            sx={{ margin: '5px' }}
          >
            Select
          </Button>
        );
      },
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(search);
    }
  };

  const handleSearch = () => fetchData(search);

  const fetchData = useCallback(
    (searchQuery) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([_, v]) => v !== '')
      );

      if (isEmpty(validSearchValues)) {
        return;
      }

      restService
        .callEndpoint({
          endpoint: 'searchUsers',
          body: { pageSize, page: currentPage, query: validSearchValues },
          method: 'post',
        })
        .then((response) => {
          setUsers(response?.users ?? []);
          setTotalRowCount(response?.totalCount ?? 0);
          setPaginationChanged(false);
        });
    },
    [currentPage, pageSize]
  );

  useEffect(() => {
    if (paginationChanged) {
      fetchData(search);
    }
  }, [fetchData, paginationChanged, search]);

  useEffect(() => {
    fetchData(search);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: 'blur(15px)' }}
    >
      <Box
        sx={{
          position: 'absolute',
          flex: 1,
          padding: '2rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '70%',
          bgcolor: 'background.paper',
          borderRadius: '2rem',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'text.primary',
            marginBottom: '1rem',
            marginTop: '1rem',
          }}
        >
          Search for User
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <TextField
            label="First Name"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={search.firstName}
            onChange={(event) => setSearch({ ...search, firstName: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={search.lastName}
            onChange={(event) => setSearch({ ...search, lastName: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Email"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={search.email}
            onChange={(event) => setSearch({ ...search, email: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
        <Box sx={{ marginTop: '1rem' }}>
          <DataTable
            columns={columns}
            rows={users}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        </Box>
      </Box>
    </Modal>
  );
};

UserSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default UserSearchModal;
