import { useCallback, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box, Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { get, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';

import restService from '../services/restService';

const SearchBar = ({ onSearch, searchQuery, setSearchQuery }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <TextField
        label="email"
        variant="outlined"
        sx={{ flexGrow: 5 }}
        value={searchQuery.email}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, email: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="first name"
        variant="outlined"
        sx={{ flexGrow: 2 }}
        value={searchQuery.firstName}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, firstName: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="last name"
        variant="outlined"
        sx={{ flexGrow: 3 }}
        value={searchQuery.lastName}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, lastName: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onSearch}>
        Search
      </Button>
    </Box>
  );
};
SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  setSearchQuery: PropTypes.func.isRequired,
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  setUserState,
  onPaginationChange = noop,
}) => {
  const columns = [
    {
      field: 'identity.rockId',
      headerName: 'Rock Id',
      flex: 1,
      valueGetter: (params) => params.row.identity?.rockId,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 2,
      valueGetter: (params) => params.row.profile.firstName,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 2,
      valueGetter: (params) => params.row.profile.lastName,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      valueGetter: (params) => params.row.profile.email,
    },
    {
      field: 'CreatedAt',
      headerName: 'Created At',
      flex: 2,
      valueGetter: (params) => {
        return new Date(params.row.createdAt).toLocaleDateString('en-US');
      },
    },
    {
      field: 'Deleted',
      headerName: 'Deleted',
      flex: 1,
      valueGetter: (params) => params.row.deleted,
    },
    {
      field: 'Banned',
      headerName: 'Banned',
      flex: 1,
      valueGetter: (params) => params.row.roles?.includes('banned'),
    },
    {
      field: 'View Journals',
      headerName: 'View Journals',
      flex: 2,
      renderCell: (params) => (
        <>
          <MenuBookIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setUserState({
                selectedUser: params.row,
                showViewJournalsModal: true,
              })
            }
          />
        </>
      ),
    },
    {
      field: 'Edit User',
      headerName: 'Edit User',
      flex: 1,
      renderCell: (params) => (
        <>
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setUserState({
                selectedUser: params.row,
                showEditUserModal: true,
              })
            }
          />
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage,
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      mongoId: PropTypes.string,
      mpContactId: PropTypes.string,
      rockId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setUserState: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const SearchForUsers = ({ setUserState }) => {
  const [searchQuery, setSearchQuery] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  const [usersFound, setUsersFound] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const fetchData = useCallback(
    async (page, size) => {
      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([_, v]) => v !== '')
      );
      if (Object.keys(validSearchValues).length) {
        const body = { query: validSearchValues, page, pageSize: size };
        restService
          .callEndpoint({ endpoint: 'searchUsers', body, method: 'post' })
          .then((data) => {
            setUsersFound(get(data, 'users', []));
            setTotalRowCount(get(data, 'totalCount', 0));
            setPaginationChanged(false);
          });
      }
    },
    [searchQuery]
  );

  const handleSearch = () => {
    fetchData(currentPage, pageSize);
  };

  useEffect(() => {
    if (paginationChanged) {
      fetchData(currentPage, pageSize);
    }
  }, [currentPage, fetchData, pageSize, paginationChanged]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <SearchBar
        onSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {!isEmpty(usersFound) && (
        <SearchResults
          rows={usersFound}
          totalRowCount={totalRowCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setUserState={setUserState}
          onPaginationChange={() => setPaginationChanged(true)}
        />
      )}
    </Box>
  );
};
SearchForUsers.propTypes = {
  setUserState: PropTypes.func.isRequired,
};

export default SearchForUsers;
